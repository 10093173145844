import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0da1703c&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionHero: require('/codebuild/output/src1293878547/src/biennale/components/section/Hero.vue').default,SectionConcept: require('/codebuild/output/src1293878547/src/biennale/components/section/Concept.vue').default,SectionPhotographer: require('/codebuild/output/src1293878547/src/biennale/components/section/Photographer.vue').default,SectionMap: require('/codebuild/output/src1293878547/src/biennale/components/section/Map.vue').default,SectionTimeline: require('/codebuild/output/src1293878547/src/biennale/components/section/Timeline.vue').default,SectionVideo: require('/codebuild/output/src1293878547/src/biennale/components/section/Video.vue').default,Footer: require('/codebuild/output/src1293878547/src/biennale/components/global/Footer.vue').default,SmoothScroll: require('/codebuild/output/src1293878547/src/biennale/components/global/SmoothScroll.vue').default,SEO: require('/codebuild/output/src1293878547/src/biennale/components/global/SEO.vue').default,PreviewModeWrapper: require('/codebuild/output/src1293878547/src/biennale/components/global/PreviewModeWrapper.vue').default})
