
export default {
  name: "Map",
  props: {
    map: Object,
    isIndex: Boolean,
  },
  mounted() {
    // ToDo prevent google from loading Roboto font
    //  ref https://stackoverflow.com/questions/25523806/google-maps-v3-prevent-api-from-loading-roboto-font/35993046#35993046
    window.initMap = this.initMap;
    this.loadScript();
  },
  methods: {
    loadScript() {
      if (window.google && window.google.maps) {
        this.initMap();
        return;
      }

      // const self = this;
      const script = document.createElement("script");
      script.onload = function () {
        if (!window.google && !window.google.maps)
          return void (console.error("no google maps script included"));
        // self.initMap();
      };
      script.async = true;
      script.defer = true;
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCMT_voNtTOGwQmfcGhwHF0bD9f-Rt_XAQ&callback=initMap&v=weekly";
      document.getElementsByTagName("head")[0].appendChild(script);
    },
    initMap() {
      // console.log('initMap()')
      const myLatLng = {lat: 45.433769, lng: 12.342268};
      const mapOptions = {
        panControl: true,
        zoomControl: true,
        mapTypeControl: false,
        fullscreenControl: false,
        keyboardShortcuts: false,
        scaleControl: true,
        streetViewControl: false,
        overviewMapControl: false,
        rotateControl: false
      }
      const map = new window.google.maps.Map(document.getElementById("map"), {
        zoom: 14,
        center: myLatLng,
        mapId: '637ac1f9626ea275',
        ...mapOptions
      });
      const markers = []

      const pinColor = "#000000";
      // Pick your pin (hole or no hole)
      const pinSVGHole = "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
      const pinSVGFilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
      const labelOriginFilled = new google.maps.Point(12, 9);


      const markerImage = {  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
        path: pinSVGFilled,
        anchor: new window.google.maps.Point(12, 17),
        fillOpacity: 1,
        fillColor: pinColor,
        strokeWeight: 2,
        strokeColor: "white",
        scale: 2,
        labelOrigin: labelOriginFilled
      };

      const markerImage2 = {  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
        path: pinSVGHole,
        anchor: new window.google.maps.Point(12, 17),
        fillOpacity: 1,
        fillColor: pinColor,
        strokeWeight: 2,
        strokeColor: "white",
        scale: 2,
        labelOrigin: labelOriginFilled
      };

      markers.push(new window.google.maps.Marker({
        position: {lat: 45.42962996976396, lng: 12.326716840312708},
        map,
        title: "CENTRO CULTURALE",
        icon: markerImage2,
      }));

      markers.push(new window.google.maps.Marker({
        position: {lat: 45.43485, lng: 12.349901},
        map,
        title: "ARSENALE DI VENEZIA",
        icon: markerImage,
      }));

      markers.push(new window.google.maps.Marker({
        position: {lat: 45.428772, lng: 12.358081},
        map,
        title: "GIARDINI DELLA BIENNALE",
        icon: markerImage,
      }));

      markers.forEach(marker => {
        if (marker.title === "CENTRO CULTURALE") {
          this.addInfoWindow(marker,
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<h1 id="firstHeading" class="firstHeading text-black mb-2">Bulgarian Pavillion</h1>' +
            '<div id="bodyContent">' +
            '<p><a href="https://goo.gl/maps/XC3AEgUppnEUhFiGA" target="_blank" class="underline">' +
            "View on Google Maps</a></p>" +
            "</div>" +
            "</div>");
        } else if (marker.title === "ARSENALE DI VENEZIA") {
          this.addInfoWindow(marker,
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<h1 id="firstHeading" class="firstHeading text-black mb-2">Arsenale di Venezia</h1>' +
            '<div id="bodyContent">' +
            '<p><a href="https://goo.gl/maps/qf5rJMHf3tkq9FHi8" target="_blank" class="underline">' +
            "View on Google Maps</a></p>" +
            "</div>" +
            "</div>");
        } else if (marker.title === "GIARDINI DELLA BIENNALE") {
          this.addInfoWindow(marker,
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<h1 id="firstHeading" class="firstHeading text-black mb-2">Giardini della Biennale</h1>' +
            '<div id="bodyContent">' +
            '<p><a href="https://goo.gl/maps/HfAEYvQq2gV6UqkL8" target="_blank" class="underline">' +
            "View on Google Maps</a></p>" +
            "</div>" +
            "</div>");
        }
      })
    },
    addInfoWindow(marker, message) {
      const infoWindow = new window.google.maps.InfoWindow({
        content: message
      });

      window.google.maps.event.addListener(marker, 'click', function () {
        infoWindow.open(map, marker);
      });
    }
  }
}
