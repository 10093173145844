export default {
  data() {
    return {
      previewMode: false,
    };
  },
  watch: {
    previewMode: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$nextTick(() => {
            if (this.resetLocoScroll) {
              this.resetLocoScroll();
            }
          });
        }
      },
      immediate: true,
    },
  },
};
