

export default {
  name: "SwiperCarrousel",
  props: {
    slides: Array,
  },
  data() {
    return {
      swiper: null,
      swiperOptions: {
        centeredSlides: false,
        slidesPerView: 1.10,
        spaceBetween: 13,
        loopAdditionalSlides: 3,
        loop: true,
        // slidesOffsetBefore: ,
        // slidesPerView: 2,
        // spaceBetween: 20,
        // centeredSlides: true,
        // loop: true,
        // loopAdditionalSlides: 30,
        // // slidesPerGroup: 1,
        // // slidesOffsetBefore: 20,
        // // loopFillGroupWithBlank: true,
        // // roundLengths: true,
        // // pagination: false,
        // // navigation: {
        // //   nextEl: '.swiper-button-next',
        // //   prevEl: '.swiper-button-prev'
        // // },
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          // 320: {
          //   spaceBetween: 10,
          //   slidesPerView: 1.15,
          //   draggable: false,
          //   slidesOffsetBefore: 20,
          // },
          // when window width is >= 768px
          768: {
            slidesPerView: 1.32,
            centeredSlides: true,
          },
        }
      }
    };
  },
  mounted() {
    this.initializeSwiper();
  },
  methods: {
    initializeSwiper() {
      this.swiper = new this.$swiper('.swiper', {
        // modules: [this.$swiperModules.Navigation],
        ...this.swiperOptions
      });
    },
    getImageOfSize(size, index) {
      if (this.slides[index] && this.slides[index].attributes && this.slides[index].attributes.formats) {
        const image = this.slides[index].attributes.formats[size];
        return image ? image.url : '';
      } else {
        return '';
      }
    },
  }
};
