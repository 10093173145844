
import * as THREE from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader.js';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {mapMutations} from "vuex";

export default {
  name: 'Globe',
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      model: null,
      controls: null, // add this line
      userInteracted: false,
      rotationSpeed: 0.0035,
    };
  },
  mounted() {
    this.initThree();
    this.animate();
    // add window resize listener
    window.addEventListener('resize', this.onWindowResize);
  },
  methods: {
    ...mapMutations({
      setLoadingModelProgress: 'setLoadingModelProgress',
    }),
    initThree() {
      const container = this.$refs.threeContainer;

      this.camera = new THREE.PerspectiveCamera(75, container.offsetWidth / container.offsetHeight, 0.1, 1000);
      this.camera.position.z = 3;
      this.camera.position.y = 0;

      this.scene = new THREE.Scene();

      // const light = new THREE.AmbientLight(0xffffff);
      // this.scene.add(light);

      this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
      this.renderer.setClearColor(0xffffff, 0);
      this.renderer.setSize(container.offsetWidth, container.offsetHeight);
      container.appendChild(this.renderer.domElement);

      // Add ambient light
      const ambientLight = new THREE.AmbientLight(0xffffff); // soft white light
      this.scene.add(ambientLight);
      //
      // // Add directional light
      const directionalLight = new THREE.DirectionalLight(0x9daf92, 0.5);
      directionalLight.position.set(0, 1, 0); // set the direction
      this.scene.add(directionalLight);
      //
      // Add point light
      // const pointLight = new THREE.PointLight(0x9daf92, 0.8, 100);
      // pointLight.position.set(50, 50, 50); // set the position
      // this.scene.add(pointLight);

      // loading the 3D model
      const loader = new GLTFLoader();

      // Setup DRACOLoader
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('/js/draco/'); // adjust this to point to where your Draco decoder files are
      loader.setDRACOLoader(dracoLoader); // Set DRACOLoader instance to GLTFLoader


      loader.load('/models/globe-o.glb', (gltf) => {
          this.model = gltf.scene;

          // Update the material color of the model
          this.model.traverse((child) => {
            if (child.isMesh) {
              // If the child is a Mesh type (has a geometry and material), then modify its color.
              child.material.color.setHex(0x9daf92); // This would change the color to red
            }
          });

          this.scene.add(this.model);

          // Initiate controls after model has loaded
          this.controls = new OrbitControls(this.camera, this.renderer.domElement);

          // Disable zoom
          this.controls.enableZoom = false;

          // Restrict rotation to Y axis
          // this.controls.minPolarAngle = Math.PI / 3; // radians
          this.controls.maxPolarAngle = Math.PI / 3; // radians

          // Set to true when user starts interacting
          this.controls.addEventListener('start', () => {
            this.userInteracted = true;
          });

          // Set back to false when user stops interacting
          this.controls.addEventListener('end', () => {
            this.userInteracted = false;
          });
        },
        (xhr) => {
          // Calculate real progress
          const realProgress = xhr.loaded / xhr.total * 100;
          this.setLoadingModelProgress(realProgress);
        },
        (error) => {
          console.error(error);
        }
      );
    },
    animate() {
      requestAnimationFrame(this.animate);
      if (this.controls) {
        this.controls.update();
      }

      if (this.model) {
        if (this.userInteracted) {
          // do nothing when user is interacting
        } else {
          this.model.rotation.y += this.rotationSpeed;
        }
      }

      this.renderer.render(this.scene, this.camera);
    },
    onWindowResize() {
      const container = this.$refs.threeContainer;

      // update the camera's aspect ratio and the renderer's size
      this.camera.aspect = container.offsetWidth / container.offsetHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(container.offsetWidth, container.offsetHeight);
    },
  },
  beforeDestroy() {
    // remove window resize listener when the component is destroyed
    window.removeEventListener('resize', this.onWindowResize);
  },
}
