import imagesLoaded from "imagesloaded";

export default {
  computed: {
    loadingModelProgress() {
      return this.$store.state.loadingModelProgress
    },
  },
  data() {
    return {
      locoScroll: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.initLocomotiveScroll();
      }, 200);

      setTimeout(() => {
        this.locoScroll.update()
      }, 3000);
    });
  },
  beforeDestroy() {
    // this.saveScrollPosition();
    this.destroyLocomotiveScroll();
  },
  methods: {
    initLocomotiveScroll() {
      const scrollContainer = this.$el.querySelector('[data-scroll-container]');
      if (!scrollContainer) {
        console.error('Scroll container not found');
        return;
      }

      this.locoScroll = this.$initLocomotiveScroll(scrollContainer);

      // Update ScrollTrigger after Locomotive Scroll updates
      this.locoScroll.on('scroll', (t) => {
        document.documentElement.setAttribute("data-direction", t.direction)
      });
      this.locoScroll.on('scroll', () => {
        this.$ScrollTrigger.update()
      });

      // // Add a setTimeout to wait for Locomotive Scroll to fully initialize
      // const prevLanguage = this.$store.state.prevLanguage;
      // const currentLanguage = this.$i18n.locale;
      //
      // if (prevLanguage && prevLanguage !== currentLanguage) {
      //   // $nextTick doesn't do the job alone, we need a timeout to be sure that locoScroll has initialized
      //   this.$nextTick(() => {
      //     setTimeout(() => {
      //       const savedScrollPosition = this.$store.state.scrollPosition;
      //       this.locoScroll.scrollTo(savedScrollPosition, {
      //         duration: 0,
      //         disableLerp: true
      //       });
      //     }, 100); // Adjust this delay if needed
      //   });
      // }

      // Refresh ScrollTrigger after Locomotive Scroll initialization
      const vm = this;

      this.$ScrollTrigger.scrollerProxy('[data-scroll-container]', {
        scrollTop(value) {
          if (!vm.locoScroll) return 0;

          return arguments.length ? vm.locoScroll.scrollTo(value, {
            duration: 0,
            disableLerp: true
          }) : vm.locoScroll.scroll.instance.scroll.y;
        }, // we don't have to define a scrollLeft because we're only scrolling vertically.
        getBoundingClientRect() {
          return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
        },
        // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
        pinType: document.querySelector('[data-scroll-container]').style.transform ? "transform" : "fixed"
      });

      // Define refreshHandler
      this.refreshHandler = () => this.locoScroll.update();

      /* COLOR CHANGER */
      this.colorChangerHandler = () => {
        const scrollColorElems = document.querySelectorAll("[data-bgcolor]");

        scrollColorElems.forEach((colorSection, i) => {
          const prevBg = i === 0 ? "" : scrollColorElems[i - 1].dataset.bgcolor;
          const prevText = i === 0 ? "" : scrollColorElems[i - 1].dataset.textcolor;

          this.$ScrollTrigger.create({
            trigger: colorSection,
            scroller: "[data-scroll-container]",
            start: "top 50%",
            onEnter: () => {
              this.$gsap.to("body", {
                backgroundColor: colorSection.dataset.bgcolor,
                color: colorSection.dataset.textcolor,
                borderColor: colorSection.dataset.bgcolor,
                overwrite: 'auto'
              })
            },
            onLeaveBack: () => {
              this.$gsap.to("body", {
                backgroundColor: prevBg,
                color: prevText,
                borderColor: prevBg,
                overwrite: 'auto'
              })
            }
          });
        });
      };

      if (document.readyState === 'complete') {
        // If document has fully loaded
        // Define an interval that will check for the loading progress
        const checkModelLoadedInterval = setInterval(() => {
          if (this.$route.path !== '/' || this.loadingModelProgress === 100) {
            // If condition is met, clear the interval and run the function
            clearInterval(checkModelLoadedInterval)
            this.colorChangerHandler();
          }
        }, 100);  // Repeat every 100ms
      } else {
        // If document is still loading
        window.onload = () => {
          this.colorChangerHandler();
        };
      }

      // fix locomotive bug on setting up too early (img not yet loaded)
      imagesLoaded(scrollContainer, {background: true}, () => {
        this.locoScroll.update();
      });

      this.$ScrollTrigger.addEventListener('refresh', () => this.refreshHandler);
      this.$ScrollTrigger.refresh();
      this.$store.commit('setLocomotiveScrollInitialized', true);
    },
    destroyLocomotiveScroll() {
      // Remove the 'refresh' event listener before destroying the Locomotive Scroll instance
      this.$ScrollTrigger.removeEventListener('refresh', this.refreshHandler);

      // Clean up before destroying the component
      this.$ScrollTrigger.getAll().forEach(st => st.kill());
      this.locoScroll.off('scroll', this.$ScrollTrigger.update);
      this.locoScroll.destroy();
      this.locoScroll = null;
      this.$store.commit('setLocomotiveScrollInitialized', false);
    },
    resetLocoScroll() {
      if (this.locoScroll) {
        this.destroyLocomotiveScroll();
        this.initLocomotiveScroll();
      }
    },
    // saveScrollPosition() {
    //   if (this.locoScroll) {
    //     const scrollPosition = this.locoScroll.scroll.instance.scroll.y;
    //     this.$store.commit('setScrollPosition', scrollPosition);
    //   }
    // },
  },
};
