
export default {
  name: 'AnimationScrollSequence',
  props: {
    items: Array,
  },
  computed: {
    isLocomotiveScrollInitialized() {
      return this.$store.state.isLocomotiveScrollInitialized;
    }
  },
  watch: {
    isLocomotiveScrollInitialized(newVal) {
      if (newVal) {
        // Locomotive Scroll has been initialized
        this.initAnimationSequence();
      }
    }
  },
  data() {
    return {
      canvas: null,
      context: null,
      images: [],
    };
  },
  methods: {
    async initAnimationSequence() {
      this.canvas = document.getElementById("hero-lightpass");
      this.context = this.canvas.getContext("2d");

      this.canvas.height = 1080;
      this.canvas.width = 1920;

      const frameCount = 35;

      const currentFrame = index => (
        `/animations/sequence/optimized/new/${(index).toString().padStart(4, '0')}.png`
      );

      const loadPromises = Array.from({length: frameCount}, (_, i) => this.loadImage(currentFrame(i)));
      this.images = await Promise.all(loadPromises);

      this.render(0);
    },
    loadImage(src) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      });
    },
    render(i) {
      // console.log(i)
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.context.drawImage(this.images[i], 0, 0, this.canvas.width, this.canvas.height);
    }
  }
}
