import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=f4ebc590&"
import script from "./Hero.vue?vue&type=script&lang=js&"
export * from "./Hero.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Globe: require('/codebuild/output/src1293878547/src/biennale/components/globe.vue').default})
