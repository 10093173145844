
export default {
  name: "Concept",
  props: {
    concept: Object,
    locoScroll: Object,
  },
  computed: {
    isLocomotiveScrollInitialized() {
      return this.$store.state.isLocomotiveScrollInitialized;
    }
  },
  watch: {
    isLocomotiveScrollInitialized(newVal) {
      if (newVal) {
        // Locomotive Scroll has been initialized
        this.initAnimationSequence();

        // Refresh Locomotive Scroll after a delay to ensure all content is loaded and layout is stable
        this.$nextTick(() => {
          setTimeout(() => {
            this.locoScroll.update();
          }, 300); // adjust time as needed
        });
      }
    }
  },
  data() {
    return {
      currentProgress: 0,
    };
  },
  methods: {
    initAnimationSequence() {
      const container = this.$refs['container'];
      const spacer = document.getElementById("spacer");

      let scrollTriggerInstance = this.$gsap.to(container, {
        scrollTrigger: {
          trigger: container,
          scrub: 0.5,
          pin: true,
          anticipatePin: 1, // add this line
          scroller: window.matchMedia("(max-width: 768px)").matches ? null : "[data-scroll-container]",
          start: "top top",
          endTrigger: spacer,
          end: "center top",
          onUpdate: (context) => {
            let i = Math.round(context.progress * 34);
            if (this.currentProgress !== i) {
              this.$refs.animationSequence.render(i);
              this.currentProgress = i;
            }
          },
        },
      });
    }
  }
}
