
export default {
  name: "Timeline",
  props: {
    timeline: Object,
    locoScroll: Object,
  },
  data() {
    return {
      date: null,
      visitors: 0,
      visitorsIncremented: false,
    };
  },
  computed: {
    isLocomotiveScrollInitialized() {
      return this.$store.state.isLocomotiveScrollInitialized;
    }
  },
  watch: {
    isLocomotiveScrollInitialized(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.locoScroll.on("scroll", (args) => {
            const element = this.$refs.visitors;
            const bounding = element.getBoundingClientRect();

            if (bounding.top >= 0 && bounding.bottom <= window.innerHeight && !this.visitorsIncremented) {
              this.incrementVisitors();
            }
          });
        });
      }
    }
  },
  mounted() {
    this.date = new Date();
    this.visitors = this.timeline.visitors - 30;

    if (!this.isLocomotiveScrollInitialized) {
      this.setupIntersectionObserver();
    }
  },
  methods: {
    incrementVisitors(incrementBy = 30, duration = 4) {
      this.visitorsIncremented = true;
      this.$gsap.to(this.$data, {
        visitors: "+=" + incrementBy,
        duration: duration,
        ease: "power3.out",
        onUpdate: () => {
          this.visitors = Math.floor(this.visitors);
        },
      });
    },
    setupIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !this.visitorsIncremented) {
            this.incrementVisitors();
          }
        });
      }, options);

      observer.observe(this.$refs.visitors);
    },
  },
}
