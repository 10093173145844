
import homeQuery from "@/apollo/queries/pages/home";
import locomotiveScrollMixin from "@/mixins/locomotiveScrollMixin";
import previewModeMixin from '@/mixins/previewModeMixin';

export default {
  name: 'index',
  mixins: [locomotiveScrollMixin, previewModeMixin],
  async asyncData({app, store}) {
    const previewMode = store.state.previewMode;

    if (previewMode) {
      await app.apolloProvider.defaultClient.resetStore();
    }

    const responseHome = await app.apolloProvider.defaultClient.query({
      query: homeQuery,
      variables: {
        locale: app.i18n.locale,
      },
    }).catch((err) => {
      console.log(JSON.stringify(err, null, 2));
    });

    return {
      home: responseHome.data.home,
      previewMode,
    };
  },
  data() {
    return {
      home: null,
    };
  },
}
