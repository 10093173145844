import { render, staticRenderFns } from "./Photographer.vue?vue&type=template&id=5b4b4cb6&"
import script from "./Photographer.vue?vue&type=script&lang=js&"
export * from "./Photographer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SwiperCarrousel: require('/codebuild/output/src1293878547/src/biennale/components/SwiperCarrousel.vue').default})
