
export default {
  name: "Picture",
  props: {
    imgID: {
      type: String,
      required: false,
      default: ''
    },
    imgClass: {
      type: String,
      required: false,
      default: ''
    },
    alt: {
      type: String,
      default: "Image"
    },
    smallURL: {
      type: String,
      required: true,
    },
    mediumURL: {
      type: String,
      required: false,
      default: ''
    },
    largeURL: {
      type: String,
      required: false,
      default: ''
    },
    xlargeURL: {
      type: String,
      required: false,
      default: ''
    },
  },
  methods: {
    toWebp(string) {
      return string.replace(/\.(jpg|png)/g, '.webp')
    }
  }
}
